export interface LastViewedLessonI {
  lessonIdx: number;
  chapterIdx: number;
  videoCurrentTime: number;
  initial: boolean;
}

export interface SettingsI {
  loadLastViewedLesson: boolean;
  nextOnVideoFinish: boolean;
}

interface returnValue {
  getLastViewedLesson: () => LastViewedLessonI;
  getSettings: () => SettingsI;
  updateSettings: (update: Partial<SettingsI>) => void;
  updateLastViewedLesson: (update: Partial<LastViewedLessonI>) => void;
}

export const UseLocalStorageData = (): returnValue => {
  const LS_KEYS = {
    SETTINGS: "settings",
    LAST_VIEWED_LESSON: "last-viewed-lesson",
  };
  const getInitialLcValue = (key: string, initalValueIfNull: any) => {
    const dataExists = localStorage.getItem(key);
    if (dataExists) return JSON.parse(dataExists);
    localStorage.setItem(key, JSON.stringify(initalValueIfNull));
    return initalValueIfNull;
  };

  const getSettings = () =>
    getInitialLcValue(LS_KEYS.SETTINGS, {
      loadLastViewedLesson: false,
      nextOnVideoFinish: false,
    }) as SettingsI;

  const getLastViewedLesson = () =>
    getInitialLcValue(LS_KEYS.LAST_VIEWED_LESSON, {
      lessonIdx: 0,
      chapterIdx: 0,
      videoCurrentTime: 0,
      initial: true,
    }) as LastViewedLessonI;

  const updateSettings = (update: Partial<SettingsI>) => {
    const settings = getSettings();
    const updated = { ...settings, ...update };
    localStorage.setItem(LS_KEYS.SETTINGS, JSON.stringify(updated));
  };

  const updateLastViewedLesson = (update: Partial<LastViewedLessonI>) => {
    const lastViewedLesson = getLastViewedLesson();
    const updated = { ...lastViewedLesson, ...update };
    localStorage.setItem(LS_KEYS.LAST_VIEWED_LESSON, JSON.stringify(updated));
  };

  return {
    getSettings,
    getLastViewedLesson,
    updateSettings,
    updateLastViewedLesson,
  };
};
