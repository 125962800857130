import styles from "./statusMessage.module.scss";
interface ButtonI {
  text: string;
  href: string;
}
const StatusMessage: React.FC<{
  title: string;
  iconClassName: string;
  description?: string;
  descriptionSmall?: string;
  buttons: ButtonI[];
}> = (props) => {
  return (
    <div className={styles.statusMessageContainer}>
      <div className={styles.statusMessage}>
        <i className={props.iconClassName} />
        <h1>{props.title}</h1>
        <h3>{props.description}</h3>
        <h4>{props.descriptionSmall}</h4>
        <div className={styles.buttons}>
          {props.buttons.map((b) => (
            <a href={b.href}>
              <button>{b.text}</button>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusMessage;
