import React from "react";
import styles from "./document.module.scss";
import classNames from "classnames";
import { isProduction } from "../../utils";

interface Props {
  className?: string;
  id: number;
  name: string;
  fileType: string;
}
const Document: React.FC<Props> = ({ className, id, name, fileType }) => {
  return (
    <a
      className={styles.document}
      href={
        (isProduction()
          ? "https://api.newmanguitar.co.il/courses/course-system/files/documents/"
          : "/api/courses/course-system/files/documents/") + id
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className={classNames(styles.container)}>
        <h3>{name}</h3>
        <p>{fileType}</p>
        <i className="fas fa-file" />
      </div>
    </a>
  );
};

export default Document;
