//@typescript-eslint/no-unused-vars
import { createContext, useState } from "react";

interface LastViewedLesson {
  lessonIdx: number;
  chapterIdx: number;
  videoCurrentTime: number;
  initial: boolean;
}

interface Settings {
  loadLastViewedLesson: boolean;
  nextOnVideoFinish: boolean;
}

interface cxtValue {
  lastViewedLesson: LastViewedLesson;
  settings: Settings;
  updateSettings: (update: Partial<Settings>) => void;
  updateLastViewedLesson: (update: Partial<LastViewedLesson>) => void;
}

const LC_KEYS = {
  SETTINGS: "settings",
  LAST_VIEWED_LESSON: "last-viewed-lesson",
};

const LocalStorageDataContext = createContext<cxtValue>({} as cxtValue);

const LocalStorageDataProvider: React.FC<{}> = (props) => {
  const getInitialLcValue = (key: string, initalValueIfNull: any) => {
    const dataExists = localStorage.getItem(key);
    if (dataExists) return JSON.parse(dataExists);
    localStorage.setItem(key, JSON.stringify(initalValueIfNull));
    return initalValueIfNull;
  };

  const [lastViewedLesson, setLastViewedLesson] = useState(
    getInitialLcValue(LC_KEYS.LAST_VIEWED_LESSON, {
      lessonIdx: 0,
      chapterIdx: 0,
      videoCurrentTime: 0,
      initial: true,
    }) as LastViewedLesson
  );

  const [settings, setSettings] = useState(
    getInitialLcValue(LC_KEYS.SETTINGS, {
      loadLastViewedLesson: true,
      nextOnVideoFinish: false,
    }) as Settings
  );

  const updateSettings = (update: Partial<Settings>) => {
    setSettings((s) => {
      const updated = { ...s, ...update };
      localStorage.setItem(LC_KEYS.SETTINGS, JSON.stringify(updated));
      return updated;
    });
  };
  const updateLastViewedLesson = (update: Partial<LastViewedLesson>) => {
    setLastViewedLesson((s) => {
      const updated = { ...s, ...update };
      localStorage.setItem(LC_KEYS.LAST_VIEWED_LESSON, JSON.stringify(updated));
      return updated;
    });
  };

  const value = {
    settings,
    lastViewedLesson,
    updateLastViewedLesson,
    updateSettings,
  };
  return (
    <LocalStorageDataContext.Provider value={value}>
      {props.children}
    </LocalStorageDataContext.Provider>
  );
};
export { LocalStorageDataProvider, LocalStorageDataContext };
