import classNames from "classnames";
import React, { useState } from "react";
import { UseLocalStorageData } from "../../hooks/UseLocalStorageData";
import { SettingsI } from "../../hooks/UseLocalStorageData";
import ToogleSwitch from "../UI/ToogleSwitch";
import styles from "./dropdown.module.scss";
const DropdownSettings: React.FC = () => {
  const { getSettings, updateSettings } = UseLocalStorageData();
  const [settingsValue, setSettingsValue] = useState(getSettings());
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof SettingsI;
    const updated = { ...settingsValue, [name]: !settingsValue[name] };
    setSettingsValue(updated);
    updateSettings(updated);
  };
  return (
    <div className={styles.dropdown}>
      <div className={classNames(styles.item, styles.textWithToogle)}>
        <label className={styles.text}>טען את השיעור האחרון בנקודה האחרונה שבה נצפה</label>
        <ToogleSwitch
          name="loadLastViewedLesson"
          checked={settingsValue.loadLastViewedLesson}
          onChange={handleChange}
        />
      </div>
      <div className={classNames(styles.item, styles.textWithToogle)}>
        <label className={styles.text}>עבור לשיעור הבא בעת סיום הסרטון</label>
        <ToogleSwitch
          name="nextOnVideoFinish"
          checked={settingsValue.nextOnVideoFinish}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default DropdownSettings;
