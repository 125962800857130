import React from "react";
import IndexPage from "./pages";
import { CourseContextProvider, CourseContext } from "./contexts/Course";
import { LocalStorageDataProvider } from "./contexts/LocalStorageData";
import { LayoutContextProvider } from "./contexts/Layout";
import { CourseError } from "./api/courses";
import StatusMessage from "./components/StatusMessage";
import { getCourseIdFromUrl } from "./utils";
const Loader = () => (
  <div style={{ width: "100%", height: "100%", display: "flex" }}>
    <div
      style={{
        width: "100px",
        height: "100px",
        border: "16px solid #dedede",
        borderRadius: "50%",
        borderTop: "16px solid #827ffe",
        animation: "spin 1s infinite linear",
        margin: "auto",
      }}
    />
  </div>
);

const App: React.FC = () => {
  return (
    <LayoutContextProvider>
      <LocalStorageDataProvider>
        <CourseContextProvider>
          <CourseContext.Consumer>
            {(ctx) => {
              if (ctx.courseLoadingErr) {
                switch (ctx.courseLoadingErr) {
                  case CourseError.NO_AUTH:
                    return (window.location.href =
                      "https://newmanguitar.co.il/login?redirectTo=?" + window.location.href);

                  case CourseError.COURSE_EXPIRED:
                    return (
                      <StatusMessage
                        title="נגמר זמן הקורס"
                        iconClassName="fas fa-guitar"
                        buttons={[]}
                      />
                    );

                  case CourseError.TRIAL_EXPIRED:
                    return (
                      <StatusMessage
                        title="נסיון הקורס נגמר"
                        description="גרסת הנסיון לקורס נגמרה. יש אפשרות לרכוש את הקורס המלא בכפתור למטה"
                        iconClassName="fas fa-stopwatch"
                        buttons={[
                          {
                            text: "לרכישה",
                            href: "https://newmanguitar.co.il/courses/" + getCourseIdFromUrl(),
                          },
                        ]}
                      />
                    );

                  case CourseError.NOT_FOUND:
                    return (
                      <StatusMessage
                        title="הקורס לא נמצא"
                        description="הקורס לא נמצא במערכת"
                        iconClassName="far fa-question-circle"
                        buttons={[{ text: "לחזרה לאתר הראשי", href: "https://newmanguitar.co.il" }]}
                      />
                    );

                  case CourseError.NOT_OWNED_BY_ME:
                    return (window.location.href =
                      "https://newmanguitar.co.il/courses/" + getCourseIdFromUrl());

                  default:
                    return (
                      <StatusMessage
                        title="שגיאה"
                        description="אירעה שגיאה בעת טעינת הקורס, יש לרענן ולנסות שוב"
                        iconClassName="fas fa-times"
                        buttons={[{ text: "לחזרה לאתר הראשי", href: "https://newmanguitar.co.il" }]}
                      />
                    );
                }
              }
              if (ctx.course) return <IndexPage />;
              return <Loader />;
            }}
          </CourseContext.Consumer>
        </CourseContextProvider>
      </LocalStorageDataProvider>
    </LayoutContextProvider>
  );
};

export default App;
