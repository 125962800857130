import styles from "./sidebar.module.scss";
import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { CourseContext } from "../contexts/Course";
import { LayoutContextContext } from "../contexts/Layout";

export const VideoSvg: React.FC<{ width?: number; height?: number }> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 28}
    height={height || 28}
    version="1.1"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    style={{ display: "inline" }}
  >
    <path
      fillRule="evenodd"
      d="M46 37H2a1 1 0 01-1-1V8a1 1 0 011-1h44a1 1 0 011 1v28a1 1 0 01-1 1zM45 9H3v26h42V9zm-24 7c.214 0 .4.082.563.196l7.771 4.872A.99.99 0 0130 22a.981.981 0 01-.405.783l-7.974 4.981A.982.982 0 0121 28a1 1 0 01-1-1V17a1 1 0 011-1zm-6 23h18a1 1 0 110 2H15a1 1 0 110-2z"
      clipRule="evenodd"
    />
  </svg>
);

interface LessonProps {
  id: number;
  number: number;
  name: string;
  open: boolean;
  idx?: number;
  updateSelected?: (idx: number) => void;
}

const Lesson: React.FC<LessonProps> = ({ name, id, open, number, updateSelected, idx }) => {
  const { selectedLesson } = useContext(CourseContext);
  const isSelected = selectedLesson?.id === id;
  const [btnClicked, setBtnClicked] = useState(true);
  useEffect(() => {
    if (!isSelected) return;
    if (btnClicked) return setBtnClicked(false);
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedLesson]);

  const ref = useRef<null | HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      onClick={() => {
        setBtnClicked(true);
        updateSelected?.(idx || 0);
      }}
      className={classnames(styles.lesson, { [styles.selected]: isSelected })}
    >
      <div className={styles.circleContainer}>
        <div className={classnames(styles.circle, { [styles.locked]: !open })}>
          {open ? number : <i className="fas fa-lock" />}
        </div>
      </div>

      <p>{name}</p>
    </div>
  );
};

interface ChapterProps {
  id: number;
  number: number;
  name: string;
  lessons: LessonProps[];
  idx: number;
}
const Chapter: React.FC<ChapterProps> = ({ id, lessons, name, number, idx }) => {
  const { setSidebarMovileOpen } = useContext(LayoutContextContext);
  const { updateSelectedLesson } = useContext(CourseContext);

  const updateSelected = (lessonIdx: number) => {
    updateSelectedLesson(lessonIdx, idx);
    setSidebarMovileOpen(false);
  };

  return (
    <div className={styles.chapter}>
      <div className={styles.head}>
        <VideoSvg />
        <h2>{name}</h2>
      </div>
      <div className={styles.lessons}>
        {lessons.map((l, idx) => (
          <Lesson
            key={l.id}
            updateSelected={updateSelected}
            idx={idx}
            id={l.id}
            name={l.name}
            open={l.open}
            number={l.number}
          />
        ))}
      </div>
    </div>
  );
};

interface Props {
  className?: string;
}
const Sidebar: React.FC<Props> = (props) => {
  const { course } = useContext(CourseContext);
  return (
    <div className={`${styles.sidebar} ${props.className}`}>
      <div className={styles.imageContainer}>
        <img src="https://newmanguitar.co.il/static/images/logo-white-bg.png" alt="logo" />
      </div>
      <div className={styles.courseInfo}>
        <h2>{course?.title}</h2>
        <h3>{course?.subTitle}</h3>
      </div>

      <div className={styles.chapters}>
        {course?.chapters.map((c, idx) => (
          <Chapter
            key={c.id}
            idx={idx}
            id={c.id}
            number={c.number}
            name={c.name}
            lessons={c.lessons}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
