//@typescript-eslint/no-unused-vars
import { createContext, useState } from "react";

interface cxtValue {
  sidebarMovileOpen: boolean;
  setSidebarMovileOpen: (v: boolean) => void;
}

const LayoutContextContext = createContext<cxtValue>({} as cxtValue);

const LayoutContextProvider: React.FC<{}> = (props) => {
  const [sidebarMovileOpen, setSidebarMovileOpen] = useState(false);
  const value = {
    sidebarMovileOpen,
    setSidebarMovileOpen,
  };
  return (
    <LayoutContextContext.Provider value={value}>{props.children}</LayoutContextContext.Provider>
  );
};
export { LayoutContextProvider, LayoutContextContext };
