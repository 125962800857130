import axios, { Method } from "axios";
import { isProduction } from "../utils";
export default async function makeApiRequest(
  endPoint: string,
  method: Method,
  body?: any,
  params?: any
) {
  const baseApiUrl = isProduction()
    ? "https://api.newmanguitar.co.il/courses/course-system"
    : "/courses/course-system";
  return await axios({
    url: baseApiUrl + endPoint,
    method,
    params,
    data: body,
    withCredentials: true,
  });
}
