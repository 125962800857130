import { useContext } from "react";
import { CourseContext } from "../contexts/Course";
import styles from "./header.module.scss";
import Settings from "./Settings/Settings";
interface Props {
  onNextLesson: () => void;
  onPreviousLesson: () => void;
  nextLessonDisabled: boolean;
  previousLessonDisabled: boolean;
  className?: string;
}
const Header: React.FC<Props> = (props) => {
  const { nextLesson, previousLesson } = useContext(CourseContext);
  return (
    <div className={`${styles.header} ${props.className}`}>
      <div className={styles.container}>
        <div className={styles.sidebarActions}>
          <Settings />
        </div>

        <div className={styles.buttons}>
          <div className={styles.button} onClick={() => previousLesson()}>
            <i className="fas fa-arrow-right" /> לשיעור הקודם
          </div>
          <div className={styles.button} onClick={() => nextLesson()}>
            לשיעור הבא <i className="fas fa-arrow-left" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
