import { AxiosResponse } from "axios";
import makeApiRequest from "./makeApiRequest";

export interface ChapterI {
  id: number;
  number: number;
  name: string;
  lessons: LessonI[];
}
export interface DocumentI {
  id: number;
  name: string;
  fileType: string;
}
export interface LessonI {
  id: number;
  number: number;
  name: string;
  open: boolean;
  openTime?: string;
  description: string;
  documents: DocumentI[];
}
export interface OwnedByMeI {
  trial: boolean;
}
export interface BaseCourseI {
  id: string;
  title: string;
  subTitle: string;
  description: string;
  price: number;
}
export interface CourseI extends BaseCourseI {
  chapters: ChapterI[];
  ownedByMe: OwnedByMeI;
}
export enum CourseError {
  NOT_FOUND = "NOT_FOUND",
  NO_AUTH = "NO_AUTH",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
  COURSE_EXPIRED = "COURSE_EXPIRED",
  NOT_OWNED_BY_ME = "NOT_OWNED_BY_ME",
  GENERAL = "GENERAL",
}

export default class CourseApi {
  public static async getCourseFullInfo(
    courseId: string
  ): Promise<{ data?: CourseI; err?: CourseError }> {
    try {
      const { data } = await makeApiRequest("/id/" + courseId, "GET");
      return { data: data as CourseI };
    } catch (error) {
      const axiosRes: AxiosResponse = error.response;
      if (axiosRes.status === 401) return { err: CourseError.NO_AUTH };
      if (axiosRes.status === 404) return { err: CourseError.NOT_FOUND };
      if (axiosRes.status === 403)
        switch (axiosRes.data.message) {
          case "TRIAL_EXPIRED":
            return { err: CourseError.TRIAL_EXPIRED };
          case "COURSE_EXPIRED":
            return { err: CourseError.COURSE_EXPIRED };
          case "NOT_OWNED_BY_ME":
            return { err: CourseError.NOT_OWNED_BY_ME };
          default:
            break;
        }
      return { err: CourseError.GENERAL };
    }
  }
}
