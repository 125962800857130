import { LessonI } from "../../api/courses";
import styles from "./lessonContent.module.scss";
import VideoPlayerWrapper from "./VideoPlayerWrapper";
import { VideoSvg } from "../Sidebar";
import Document from "./Document";

const LessonContent: React.FC<{ lesson: LessonI; chapterName: string }> = ({
  lesson,
  chapterName,
}) => {
  return (
    <div className={styles.lessonContent}>
      <div className={styles.titles}>
        <div className={styles.mainTitleContainer}>
          <VideoSvg width={20} height={20} />
          <h2 className={styles.chapterName}>{chapterName}</h2>
          <h2 className={styles.seperator}>|</h2>
          <h2 className={styles.lessonName}>{lesson.name}</h2>
        </div>
        <h4 className={styles.lessonDescription}>{lesson.description}</h4>
      </div>

      <div className={styles.videoContainer}>
        <VideoPlayerWrapper lessonId={lesson.id} />
      </div>

      {lesson.documents.length > 0 && (
        <div className={styles.lessonFiles}>
          <h2>קבצים מצורפים לשיעור</h2>
          <div className={styles.files}>
            {lesson.documents.map((d) => (
              <Document key={d.id} id={d.id} name={d.name} fileType={d.fileType} />
            ))}
          </div>
        </div>
      )}

      {/* {true && ( */}
      {lesson.id == 31 && lesson.number == 4 && (
        <div
          style={{
            padding: "10px",
            background: "white",
            borderRadius: "5px",
            maxWidth: "1100px",
            margin: "25px auto auto auto",
          }}
        >
          <p style={{ fontSize: "20px", marginBottom: "10px" }}>
            רוצים להתקדם לרמה הבאה בנגינה שלכם? לנגן על הגיטרה גם מעבר ל-4 סריגים ראשונים? להכיר מלא
            אקורדים, מגוון של סוגי פריטות ולנגן בחופשיות גם במים העמוקים של צוואר הגיטרה?
            <br /> לקבלת פרטים והצטרפות לקורס מכוונים גבוה - מנגן מתחיל לגיטריסט טיל כולל קופון הנחה
            מיוחד, לחצו כאן 👇
          </p>
          <a href="https://lp.vp4.me/odwt" target="_blank">
            <button
              style={{
                cursor: "pointer",
                width: "100%",
                border: "none",
                padding: "3px",
                borderRadius: "5px",
                fontSize: "23px",
                fontWeight: "bold",
                background: "#1bcec1",
                color: "white",
              }}
            >
              לחצו כאן
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default LessonContent;
