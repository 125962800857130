import Plyr from "plyr-react";
import React, { useContext, useEffect, useRef } from "react";
import "plyr-react/dist/plyr.css";
import { CourseContext } from "../../contexts/Course";
import { UseLocalStorageData } from "../../hooks/UseLocalStorageData";
import { isProduction } from "../../utils";

const VideoPlayerWrapper: React.FC<{ lessonId: number }> = ({ lessonId }) => {
  const plyrRef = useRef(null);
  const { getLastViewedLesson, getSettings, updateLastViewedLesson } = UseLocalStorageData();
  const { nextLesson } = useContext(CourseContext);

  useEffect(() => {
    const plyr = (plyrRef as any).current!.plyr;

    plyr.once("play", (e: any) => {
      const plyr = e.detail.plyr;

      /* why is this? because i cant call set usestate from here.. 
      desided to have a hacky fix because i have no time
      */
      const settings = getSettings();
      const lastViewedLesson = getLastViewedLesson();
      if (settings.loadLastViewedLesson && lastViewedLesson.videoCurrentTime !== 0) {
        plyr.currentTime = lastViewedLesson.videoCurrentTime;
      }
    });

    plyr.on("ended", () => {
      if (getSettings().nextOnVideoFinish) {
        nextLesson();
      }
    });

    plyr.on("timeupdate", () => {
      const t = Math.round(plyr.currentTime);
      if (plyr.lastUpdatedCurrentTime === t || t === 0) return;
      updateLastViewedLesson({ videoCurrentTime: t });
      plyr.lastUpdatedCurrentTime = t;
    });
  }, []);
  return (
    <Plyr
      ref={plyrRef}
      source={{
        type: "video",
        poster: "/static/images/video-poster.png",
        sources: [
          {
            src:
              (isProduction()
                ? "https://api.newmanguitar.co.il/courses/course-system/files/videos/"
                : "/api/courses/course-system/files/videos/") + lessonId,
            type: "video/mp4",
            size: 720,
          },
        ],
      }}
      options={{
        settings: ["play-large"],
        fullscreen: { iosNative: true },
        ratio: "16:9",
      }}
    />
  );
};

export default React.memo(VideoPlayerWrapper);
