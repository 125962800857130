import React, { useContext } from "react";
import styles from "./index.module.scss";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { CourseContext } from "../contexts/Course";
import LessonContent from "../components/Lesson/LessonContent";
import { LayoutContextContext } from "../contexts/Layout";
import classNames from "classnames";
import StatusMessage from "../components/StatusMessage";
const IndexPage: React.FC = () => {
  const { selectedLesson, selectedLessonChapterName, course } = useContext(CourseContext);
  const { sidebarMovileOpen } = useContext(LayoutContextContext);
  const renderLessonContent = () => {
    if (selectedLesson?.open)
      return <LessonContent chapterName={selectedLessonChapterName!} lesson={selectedLesson} />;
    if (course?.ownedByMe.trial)
      return (
        <StatusMessage
          iconClassName="fas fa-lock"
          title="השיעור נעול"
          description="יש לקנות את הקורס המלא כדי לגשת לשיעור זה"
          buttons={[
            { text: "לרכישה", href: "https://newmanguitar.co.il/checkout?courseId=" + course.id },
          ]}
        />
      );
    return (
      <StatusMessage
        iconClassName="fas fa-lock"
        title="השיעור נעול"
        description={`שיעור זה נעול ויפתח ב ${new Date(
          selectedLesson?.openTime || 0
        ).toLocaleString()}`}
        buttons={[]}
      />
    );
  };

  return (
    <div className={styles.indexPage}>
      {/* @ts-expect-error */}
      <Header className={styles.header} />
      <div className={styles.container}>
        <Sidebar
          className={classNames(styles.lessonsNavigation, {
            [styles.mobileShow]: sidebarMovileOpen,
          })}
        />

        <div className={classNames(styles.content, { [styles.mobileHidden]: sidebarMovileOpen })}>
          {renderLessonContent()}
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
