import { createContext, useEffect, useState } from "react";
import CourseApi, { CourseError, CourseI, LessonI } from "../api/courses";
import { UseLocalStorageData } from "../hooks/UseLocalStorageData";

interface cxtValue {
  course: CourseI | null;
  selectedLesson: LessonI | undefined;
  selectedLessonChapterName: string | undefined;
  updateSelectedLesson: (lessonIdx: number, chapterIdx: number) => void;
  nextLesson: () => void;
  previousLesson: () => void;
  courseLoadingErr: CourseError | null;
}

const CourseContext = createContext<cxtValue>({} as cxtValue);

const CourseContextProvider: React.FC<{}> = (props) => {
  const { getLastViewedLesson, updateLastViewedLesson, getSettings } = UseLocalStorageData();
  const [course, setCourse] = useState<CourseI | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState({ lesson: 0, chapter: 0 });
  const [updateSelectedIndexesRunCount, setUpdateSelectedIndexesRunCount] = useState(0);
  const [courseLoadingErr, setCourseLoadingErr] = useState<CourseError | null>(null);

  const updateSelectedLesson = (lessonIdx: number, chapterIdx: number) => {
    setSelectedIndexes({ chapter: chapterIdx, lesson: lessonIdx });
  };

  const getFirstopenLessonIndexes = (course: CourseI) => {
    for (let [chaptersIdx, chapter] of course.chapters.entries()) {
      for (let [lessonIdx, lesson] of chapter.lessons.entries()) {
        if (lesson.open) return { lesson: lessonIdx, chapter: chaptersIdx };
      }
    }
    return { lesson: 0, chapter: 0 };
  };

  useEffect(() => {
    const courseId = window.location.pathname.substring(1);
    console.log("cid", courseId);
    const getCourse = async () => {
      const { data, err } = await CourseApi.getCourseFullInfo(courseId);
      if (err) return setCourseLoadingErr(err);

      setCourse(data!);
      const settings = getSettings();
      if (settings.loadLastViewedLesson) {
        const lastViewedLesson = getLastViewedLesson();
        if (!lastViewedLesson.initial)
          return updateSelectedLesson(lastViewedLesson.lessonIdx, lastViewedLesson.chapterIdx);

        const { lesson, chapter } = getFirstopenLessonIndexes(data!);
        setSelectedIndexes({ lesson, chapter });
        return;
      }
      setSelectedIndexes(getFirstopenLessonIndexes(data!));
    };
    getCourse();
  }, []);

  useEffect(() => {
    //data is empty now
    if (updateSelectedIndexesRunCount === 0) return setUpdateSelectedIndexesRunCount((s) => s + 1);
    const update: any = {
      chapterIdx: selectedIndexes.chapter,
      lessonIdx: selectedIndexes.lesson,
      initial: false,
    };
    if (updateSelectedIndexesRunCount === 2) {
      // user passed lesson, reset time
      update.videoCurrentTime = 0;
    } else {
      //inital load - dont remove the vid time
      setUpdateSelectedIndexesRunCount((s) => s + 1);
    }
    updateLastViewedLesson(update);
  }, [selectedIndexes]);

  const nextLesson = () => {
    if (!course) return;
    if (course?.chapters[selectedIndexes.chapter].lessons.length - 1 > selectedIndexes.lesson)
      return setSelectedIndexes((s) => ({ chapter: s.chapter, lesson: s.lesson + 1 }));
    if (course?.chapters.length - 1 > selectedIndexes.chapter)
      return setSelectedIndexes((s) => ({ chapter: s.chapter + 1, lesson: 0 }));
  };

  const previousLesson = () => {
    if (!course) return;
    if (0 < selectedIndexes.lesson)
      return setSelectedIndexes((s) => ({ chapter: s.chapter, lesson: s.lesson - 1 }));
    if (selectedIndexes.chapter > 0)
      return setSelectedIndexes((s) => ({
        chapter: s.chapter - 1,
        lesson: course.chapters[s.chapter - 1].lessons.length - 1,
      }));
  };

  const value = {
    course,
    selectedLesson: course?.chapters[selectedIndexes.chapter]?.lessons[selectedIndexes.lesson],
    selectedLessonChapterName: course?.chapters[selectedIndexes.chapter]?.name,
    updateSelectedLesson,
    nextLesson,
    previousLesson,
    courseLoadingErr,
  };
  return <CourseContext.Provider value={value}>{props.children}</CourseContext.Provider>;
};
export { CourseContextProvider, CourseContext };
