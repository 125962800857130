import classNames from "classnames";
import React, { useContext, useRef, useState } from "react";
import DropdownSettings from "./DropdownSettings";
import styles from "./settings.module.scss";
import UseOnClickOutside from "../../hooks/useOnClickOutside";
import { LayoutContextContext } from "../../contexts/Layout";
const Settings: React.FC = () => {
  const [dropdownOpen, setDropownOpen] = useState(false);
  const { setSidebarMovileOpen, sidebarMovileOpen } = useContext(LayoutContextContext);
  const ref = useRef(null);
  UseOnClickOutside(ref, () => setDropownOpen(false));
  return (
    <div className={styles.settings} ref={ref}>
      {dropdownOpen && <DropdownSettings />}
      <i
        className={classNames(styles.mobileShow, styles.item, "fas fa-bars")}
        onClick={() => setSidebarMovileOpen(!sidebarMovileOpen)}
      />

      <i
        className={classNames("fas fa-cog", styles.item)}
        onClick={() => setDropownOpen((v) => !v)}
      />
      <a href="https://newmanguitar.co.il" className={classNames(styles.mobileHidden, styles.item)}>
        <i className="fas fa-home" />
      </a>
    </div>
  );
};
export default Settings;
