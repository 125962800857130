import classNames from "classnames";
import React from "react";
import styles from "./toogleSwitch.module.scss";
const ToogleSwitch: React.FC<{
  className?: string;
  checked: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ className, checked, onChange, name }) => {
  return (
    <label className={classNames(styles.switch, className)}>
      <input name={name} type="checkbox" checked={checked} onChange={onChange} />
      <span className={classNames(styles.slider, styles.round)}></span>
    </label>
  );
};
export default ToogleSwitch;
